const mutations = {
    // 保存token方法
    set_token(state, token = '') {
        if (token && typeof token == "string") {
            // 设置token
            state.token = token;
            localStorage.setItem('payToken', token)
        } else {
            //清除token
            state.token = "";
            localStorage.removeItem('payToken')
        }
    },
    set_commissionByMonth(state, val) {
        state.commissionByMonth = val
    },
    set_allCommission(state, val) {
        state.allCommission = val
    },
    setAddressInfo(state, val) {
        state.addressInfo = val
    },
    logout(state) {
        state.token = ''
        state.account_id = ''
        state.app_id = ''
        state.retry_count = ''
        state.num = ''
        state.commissionByMonth = ''
        state.allCommission = ''
        state.addressInfo = ''
    }
}

export default mutations