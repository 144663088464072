import Vue from 'vue'
import router from './index'
import store from '../store'
import {
  getQueryString,
} from 'utils/url.js'
import {
  wxLogin
} from 'utils/wxLogin.js'

const whiteList = ['/downUPLoad', '/login'] // 白名单

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  // 如果有token,在提现页面要判断有没有微信授权过，如果没有要进行微信授权
  // 如果没有token,跳转到登录页获取token。
  if (localStorage.getItem('payToken')) {
    if (localStorage.getItem('authorize') === '1') {
      next()
    } else {
      if (getQueryString('code') && window.location.search.indexOf('?') != -1&&!JSON.parse(localStorage.getItem("wechatAuthInfo"))) {
        store.dispatch('loginAll', {
          code: getQueryString('code')
        }).then(() => {
          next()
        })
      } else {
        next()
      }
    }
  } else {
    next()
  }
});
