import Vue from 'vue'
import {
    AddressEdit,
    Button,
    Form,
    Field,
    Tabbar,
    TabbarItem,
    Checkbox,
    CheckboxGroup,
    Grid,
    GridItem,
    Image,
    Tag,
    NoticeBar,
    Cell,
    Icon,
    Dialog,
    Notify,
    Tab,
    Tabs,
    SubmitBar,
    Popup,
    Area,
    Pagination,
    Picker,
    DatetimePicker,
    ActionSheet,
    Calendar,
    Toast,
    Empty,
    List,
    Search,
    Switch,
    SwipeCell,
    Sticky,
    Lazyload,
    Swipe,
    SwipeItem,
    CellGroup,
    DropdownMenu,
    DropdownItem
} from 'vant'
Vue.use(AddressEdit).use(Button).use(Form).use(Field).use(Tabbar).use(TabbarItem).use(Checkbox).use(CheckboxGroup).use(Grid).use(GridItem).use(Image).use(Tag).use(NoticeBar).use(Cell).use(Icon).use(Dialog).use(Notify).use(Tab).use(Tabs).use(SubmitBar).use(Popup).use(Area).use(Pagination).use(Picker).use(DatetimePicker).use(ActionSheet).use(Calendar).use(Toast).use(Empty).use(List).use(Switch).use(SwipeCell).use(Sticky).use(Lazyload).use(Search).use(Swipe).use(SwipeItem).use(CellGroup).use(DropdownMenu).use(DropdownItem)

Vue.use(Lazyload, {
    lazyComponent: true,
});