// 因为在首页及我的客户中的极讯客户修改能进入到下面两个页面，因此放在公共部分
export default [
  {
    path: "/equipData",
    name: "EquipData",
    component: () => import("views/equipData"),
    meta: {
      title: "设备SN列表",
      requireAuth: true
    }
  },
  // {
  //     path: "/transactionList",
  //     name: "TransactionList",
  //     component: () =>
  //         import ("views/transactionList"),
  //     meta: {
  //         title: "交易统计列表",
  //         requireAuth: true
  //     }
  // },
  {
    path: "/order",
    name: "Order",
    component: () => import("views/order"),
    meta: {
      title: "提现订单",
      requireAuth: true
    }
  },
  {
    path: "/order/powerbank",
    name: "Order",
    component: () => import("views/order/powerbank"),
    meta: {
      title: "充电宝订单",
      requireAuth: true
    }
  },
  {
    path: "/editPwd",
    name: "EditPwd",
    component: () => import("views/editPwd"),
    meta: {
      title: "修改密码",
      requireAuth: true
    }
  },
  {
    path: "/editAccount",
    name: "EditAccount",
    component: () => import("views/editAccount"),
    meta: {
      title: "修改账号",
      requireAuth: true
    }
  },
  {
    path: "/bindSuccess",
    name: "BindSuccess",
    component: () => import("views/bindSuccess"),
    meta: {
      title: "绑定成功",
      requireAuth: true
    }
  },
  {
    path: "/bindWx",
    name: "BindWx",
    component: () => import("views/bindWx"),
    meta: {
      title: "绑定微信",
      requireAuth: true
    }
  },
  {
    path: "/bindAlipay",
    name: "BindAlipay",
    component: () => import("views/bindAlipay"),
    meta: {
      title: "绑定支付宝",
      requireAuth: true
    }
  },
  {
    path: "/editAddress",
    name: "EditAddress",
    component: () => import("views/editAddress"),
    meta: {
      title: "修改地址",
      requireAuth: true
    }
  },
  {
    path: "/paymentCode",
    name: "PaymentCode",
    component: () => import("views/paymentCode"),
    meta: {
      title: "收款二维码和音响",
      requireAuth: true
    }
  },
  {
    path: "/store",
    name: "Store",
    component: () => import("views/store"),
    meta: {
      title: "门店管理",
      requireAuth: true
    }
  },
  {
    path: "/store/add",
    name: "StoreAdd",
    component: () => import("views/store/add"),
    meta: {
      title: "新增门店",
      requireAuth: true
    }
  },
  {
    path: "/store/edit",
    name: "StoreEdit",
    component: () => import("views/store/add"),
    meta: {
      title: "编辑门店",
      requireAuth: true
    }
  },
  {
    path: "/withdrawal/index",
    name: "Order",
    component: () => import("views/withdrawal/index"),
    meta: {
      title: "提现",
      requireAuth: true
    }
  },
];
