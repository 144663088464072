exports.statusName = (val) => {
  let statusName = '';
  switch (val) {
    case 0: {
      statusName = '已取消';
      break;
    }
    case 10: {
      statusName = '未付款';
      break;
    }
    case 20: {
      statusName = '已付款';
      break;
    }
  }
  return statusName;
}
exports.priceFormat = (val) => {
  // 这边要严谨点，当传字符串的时候要进行转换
  var val=val || 0
  var f_price = parseFloat(val)
  return f_price.toFixed(2)
}
exports.isVal = (val) => {
  // 这边要严谨点，当传字符串的时候要进行转换
  if(val){
    return val
  }else{
    return 0
  }
}
