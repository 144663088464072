// 底部导航栏
export default [{
    path: "/index",
    name: "Index",
    component: () => import("@/views/index"),
    meta: {
        title: "首页",
    }
},
{
    path: "/transactionList",
    name: "transactionList",
    component: () => import("@/views/transactionList"),
    meta: {
        title: "交易明细",
    }
},
{
    path: "/withdrawal",
    name: "Withdrawal",
    component: () => import("@/views/withdrawal"),
    meta: {
        title: "提现",
    }
},
{
    path: "/mine",
    name: "Mine",
    component: () => import("@/views/mine"),
    meta: {
        title: "我的",
    }
},
]