import Vue from 'vue'
import VueRouter from 'vue-router'
import footTabbar from './footTabbar'
import common from './common'
Vue.use(VueRouter)

// 解决 VUE-ROUTER 报 NAVIGATIONDUPLICATED 错误
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

const routes = [{
    path: "/",
    redirect: "/login"
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import("@/views/login"),
    meta: {
      title: "登录",
    }
  },
  ...footTabbar,
  ...common
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
