import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 引入基本样式
import "@/styles/base.css"
// 按需引入vant
import './vant'
// 引入全局过滤器
import '@/filters'
// 移动端适配
import 'lib-flexible'
// 路由权限配置（前置路由、微信授权等）
import './router/config'

// 引入调试器
import VConsole from 'vconsole';

if (process.env.NODE_ENV == 'development') {
  new VConsole();
}

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
